.text-primary {
  color: #4980ee !important;
}
.text-success {
  color: #80d19d !important;
}

.text-danger {
  color: #006666 !important;
}

.text-orange {
  color: #bd6534;
}

.text-white {
  color: #ffffff;
  font-weight: 500;
}
