@import "./styles/custom/";

.active {
    color: purple !important;
}

.desc {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.03px;
    padding: 1px !important;
    margin: 0 !important;
}

.no-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}