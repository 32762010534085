.fs-10 {
  font-size: 10px !important;
  letter-spacing: 0.0180093em;
  line-height: 15px;
}

.fs-11 {
  font-size: 10.5px !important;
  letter-spacing: 0.0128359em;
  line-height: 16px;
}

.fs-12 {
  font-size: 12px !important;
  letter-spacing: 0.00849077em;
  line-height: 18px;
}

.fs-13 {
  font-size: 13px !important;
  letter-spacing: 0.00484144em;
  line-height: 19px;
}

.fs-14 {
  font-size: 14px !important;
  letter-spacing: 0.00177646em;
  line-height: 22px;
}

.fs-15 {
  font-size: 15px !important;
  letter-spacing: -0.000797757em;
  line-height: 23px;
}

.fs-16 {
  font-size: 16px !important;
  letter-spacing: -0.00295978em;
  line-height: 24px;
}

.fs-17 {
  font-size: 17px !important;
  letter-spacing: -0.0127756em;
  line-height: 24px;
}

.fs-18 {
  font-size: 18px !important;
  letter-spacing: -0.0143007em;
  line-height: 25px;
}

.fs-24 {
  font-size: 24px !important;
  line-height: 32px;
}

.text-opac-50 {
  opacity: 0.5;
}

.text-opac-60 {
  opacity: 0.6;
}

.text-opac-70 {
  opacity: 0.7;
}

.text-opac-80 {
  opacity: 0.8;
}

.text-opac-90 {
  opacity: 0.9;
}

.text-center{
    text-align: center;
}